import type { ThemeUIStyleObject } from "theme-ui";

/**
 * I style with Theme UI because I want to use
 * the theme colors. At the time of writing,
 * you can't use the colors (CSS variables) inside CSS.
 */
const styles: ThemeUIStyleObject = {
  mt: 3,
  mb: 4,
  display: "inline-block",
  ".react-autosuggest__container": {
    position: "relative",
    display: "inline-block",
    minWidth: "300px",
    "@media screen and (max-width: 436px)": {
      width: "100%",
    },
  },
  ".react-autosuggest__input": {
    border: (theme) => `1px solid ${theme.colors?.highlightCode}`,
    bg: "searchInput",
    color: "text",
    width: "100%",
    p: 3,
    fontFamily: "heading",
    fontSize: 3,
    borderRadius: "4px",
  },
  ".react-autosuggest__input::placeholder": {
    color: "hexChar",
  },
  ".react-autosuggest__input--focused": {
    boxShadow: (theme) => `0 0 0 2px ${theme.colors?.accent}`,
    outline: "2px solid transparent",
    border: "1px solid transparent",
  },
  ".react-autosuggest__input--open": {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  ".react-autosuggest__suggestions-container": {
    display: "none",
  },
  ".react-autosuggest__suggestions-container--open": {
    color: "text",
    backgroundColor: "background",
    border: 0,
    boxShadow: (theme) => `0 0 0 2px ${theme.colors?.accent}`,
    display: "block",
    position: "absolute",
    width: "100%",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    overflowY: "auto",
    maxHeight: "400px",
    zIndex: 2,
  },
  ".react-autosuggest__suggestions-list": {
    m: 0,
    p: 0,
    listStyleType: "none",
  },
  ".react-autosuggest__suggestion": {
    cursor: "pointer",
    margin: 0,
  },
  ".react-autosuggest__suggestion--highlighted": {
    color: "background",
    backgroundColor: "accent",
  },
  "*::-webkit-scrollbar-track, &::-webkit-scrollbar-track": {
    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    borderRadius: "10px",
    bg: "muted",
  },
  "&::-webkit-scrollbar, *::-webkit-scrollbar": {
    width: "8px",
    bg: "muted",
  },
  "&::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
    bg: "scrollbarSearch",
  },
};

export default styles;
