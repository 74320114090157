/** @jsx jsx */
import * as React from "react";
import { Themed, jsx } from "theme-ui";
import type { PageProps } from "gatsby";
import { Link, graphql, useStaticQuery } from "gatsby";
import { z } from "zod";

import Layout from "../components/Layout";
import Search from "../components/Search";
import SEO from "../components/SEO";

const LanguagesTemplateStaticQuerySchema = z.object({
  site: z.object({
    siteMetadata: z.object({
      title: z.string(),
      description: z.string(),
      logo: z.string(),
      siteUrl: z.string(),
    }),
  }),
});

type LanguagesTemplateStaticQuery = z.infer<
  typeof LanguagesTemplateStaticQuerySchema
>;

type LanguagesTemplateProps = PageProps<object, LanguagesTemplateContext>;

const IndexPage: React.FC<LanguagesTemplateProps> = ({
  pageContext: { languages },
}) => {
  const data: LanguagesTemplateStaticQuery = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          logo
          siteUrl
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { title, description, logo, siteUrl },
    },
  } = LanguagesTemplateStaticQuerySchema.parse(data);

  return (
    <Layout
      headerContent={
        <div
          sx={{
            color: "text",
            px: [4, 5],
            pt: 4,
            pb: 4,
            borderBottom: (theme) => `1px solid ${theme.colors?.highlightCode}`,
          }}
        >
          <div sx={{ maxWidth: "calc(var(--rhythm) * 26)", margin: "0 auto" }}>
            <Themed.h1 sx={{ mt: 0 }}>Characters by language</Themed.h1>

            <Themed.p>
              See the unicode characters for a language in a character table.
              Use the input or click on a link on the list below.
            </Themed.p>

            <Search languages={languages} />
          </div>
        </div>
      }
    >
      <SEO
        title={title}
        description={description}
        image={`${siteUrl}/${logo}`}
        url={siteUrl}
      />

      <Themed.ul
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          maxHeight: "500px",
          overflow: "auto",
          listStyle: "decimal",
          mt: 5,
          py: 1,
        }}
      >
        {languages.map(({ name, path }) => (
          <Themed.li key={name} sx={{ mr: 5, my: [1, 0], p: [1, 0] }}>
            <Link to={path} sx={{ variant: "links.ui" }}>
              {name}
            </Link>
          </Themed.li>
        ))}
      </Themed.ul>

      <Themed.h2 sx={{ mt: 6 }}>How to use</Themed.h2>
      <Themed.p>
        You can search for a language by using the search box above, or by
        clicking a link. You can search by language name (e.g. Romanian), by
        unicode character (e.g. E7), or you can enter the character directly.
      </Themed.p>

      <Themed.h2>
        Details <span className="swash">&</span> limitations
      </Themed.h2>
      <Themed.p>
        The data in this site are from the{" "}
        <Themed.a href="http://cldr.unicode.org/">
          Unicode <abbr>CLDR</abbr> project.
        </Themed.a>{" "}
        Initially, they were parsed with the{" "}
        <Themed.a href="https://github.com/graphicore/specimenTools">
          specimenTools
        </Themed.a>
        , but I decided to do some custom parsing (taking the{" "}
        <Themed.a href="https://www.npmjs.com/package/cldr">cldr</Themed.a>{" "}
        <abbr>NPM</abbr> package as basis) because I wanted to support digraphs
        that have a corresponding unicode character. For example, the dz in{" "}
        <Themed.a href="/croatian">Croatian</Themed.a>.
      </Themed.p>

      <Themed.h3>Links (technical)</Themed.h3>
      <Themed.ul sx={{ li: { mb: [3, 2] } }}>
        <Themed.li>
          <Themed.a href="https://github.com/unicode-cldr/cldr-json">
            Unicode cldr in JSON.
          </Themed.a>
        </Themed.li>
        <Themed.li>
          <Themed.a href="https://github.com/unicode-cldr/cldr-misc-modern">
            cldr-misc-modern
          </Themed.a>{" "}
          and{" "}
          <Themed.a href="https://github.com/unicode-cldr/cldr-localenames-modern">
            cldr-localenames-modern.
          </Themed.a>
        </Themed.li>
        <Themed.li>
          <Themed.a href="https://github.com/unicode-cldr/cldr-misc-modern/blob/master/main/el/characters.json">
            Greek characters in cldr-misc-modern (an example).
          </Themed.a>
        </Themed.li>
        <Themed.li>
          <Themed.a href="https://github.com/graphicore/specimenTools/blob/master/build/makeChars2LanguageCoverageTable.js">
            specimenTools parsing of <abbr>cldr</abbr> data.
          </Themed.a>
        </Themed.li>
        <Themed.li>
          <Themed.a href="https://github.com/graphicore/specimenTools/blob/master/lib/services/languageCharSets.json">
            specimenTools language data.
          </Themed.a>
        </Themed.li>
      </Themed.ul>
    </Layout>
  );
};

export default IndexPage;
