/** @jsx jsx */
import * as React from "react";
import { jsx, Themed } from "theme-ui";
import { navigate } from "gatsby";
import Autosuggest from "react-autosuggest";

import autoSuggestStyles from "./autosuggestStyles";

const renderSuggestion = (language: Language) => (
  <Themed.div sx={{ p: 3 }}>
    <Themed.h4
      sx={{ fontSize: 4, my: 3, fontStyle: "normal", fontWeight: 400 }}
    >
      {language.name}
    </Themed.h4>
  </Themed.div>
);

const getSuggestions = (languages: Language[]) => (value: string) => {
  const inputValue = value.trim();
  const inputValueLowerCase = value.trim().toLowerCase();

  performance.mark("start");
  const result = languages.filter((lang) => {
    const nameMatch = lang.name.toLowerCase().includes(inputValueLowerCase);
    if (nameMatch) return nameMatch;

    const charMatch = lang.characters.find(
      ({ char, unicode, compound }) =>
        unicode === inputValueLowerCase ||
        char === inputValue ||
        compound === inputValue
    );
    return charMatch;
  });
  performance.mark("end");
  performance.measure("search-time", "start", "end");
  return result;
};

interface SearchProps {
  languages: Language[];
}

const Search: React.FC<SearchProps> = ({ languages }) => {
  const [search, setSearch] = React.useState("");
  const [suggestions, setSuggestions] = React.useState<Language[]>([]);

  // Do nothing. This is used to populate the suggestion
  // when you use the up/down keys. We use the existing
  // text the user enters (do nothing), and when they select
  // a suggestion, we navigate them to the page.
  const getSuggestionValue = () => search;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor="search-language" sx={autoSuggestStyles}>
      <strong sx={{ mb: 2, mr: 3, display: "inline-block" }}>
        Search for language:
      </strong>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={({ value }) => {
          setSuggestions(getSuggestions(languages)(value));
        }}
        onSuggestionsClearRequested={() => setSuggestions([])}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSuggestionSelected={async (event, { suggestion }) => {
          await navigate(suggestion.path);
        }}
        inputProps={{
          id: "search-language",
          name: "search-language",
          placeholder: "e.g: greek or a7 or §",
          value: search,
          onChange: (event, { newValue }) => setSearch(newValue),
        }}
      />
    </label>
  );
};

export default Search;
